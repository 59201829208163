import { ACPVariant } from 'apps/acp/variants/acp-variant';

const colors = {
  neutral: {
    color50: '#fcfcfc',
    color100: '#f8f8f8',
    color200: '#f0f0f0',
    color300: '#cccccc',
    color400: '#b3b3b3',
    color500: '#999999',
    color600: '#666666',
    color700: '#333333',
    color800: '#222222',
    color900: '#111111'
  },
  default: {
    color50: '#D5D5D5',
    color100: '#AAAAAA',
    color200: '#808080',
    color300: '#555555',
    color400: '#2B2B2B',
    color500: '#000000',
    color600: '#000000',
    color700: '#000000',
    color800: '#000000',
    color900: '#000000'
  },
  accent: {
    color50: '#D5DFF1',
    color100: '#AABEE3',
    color200: '#809ED5',
    color300: '#557DC6',
    color400: '#2B5DB8',
    color500: '#003CAA',
    color600: '#003088',
    color700: '#002466',
    color800: '#001844',
    color900: '#000C22'
  },
  positive: {
    color50: '#D5E7F7',
    color100: '#AACFEE',
    color200: '#80B8E6',
    color300: '#55A0DD',
    color400: '#2B88D5',
    color500: '#0070CC',
    color600: '#005AA3',
    color700: '#00437A',
    color800: '#002D52',
    color900: '#001629'
  },
  negative: {
    color50: '#FAD5D5',
    color100: '#F5AAAA',
    color200: '#F08080',
    color300: '#EB5555',
    color400: '#E62B2B',
    color500: '#E10000',
    color600: '#B40000',
    color700: '#870000',
    color800: '#5A0000',
    color900: '#2D0000'
  },
  special: {
    color50: '#D5DFF1',
    color100: '#AABEE3',
    color200: '#809ED5',
    color300: '#557DC6',
    color400: '#2B5DB8',
    color500: '#003CAA',
    color600: '#003088',
    color700: '#002466',
    color800: '#001844',
    color900: '#000C22'
  }
};

const variant: ACPVariant = {
  id: 'www.mlbtheshowprepaid.com',
  productTitle: 'MLB The Show Prepaid Mastercard',
  productType: 'gpr',
  accessDomain: {
    subdomain: 'www',
    domain: 'mlbtheshowprepaid',
    topLevelDomain: 'com'
  },
  colors,
  legacyAcpTheme: 'mlb-theshow-prepaid',
  legacyAcpBrandName: 'mlb-theshow-prepaid',
  legacyLegosTheme: 'mlb-theshow-prepaid',
  status_bar_background_color: colors.default.color500,
  status_bar_foreground_color: 'light',
  themeConfiguration: {
    acpDynamicFaqTheme: {
      overrideLinkColor: colors.special.color500
    },
    acpEsignTheme: {
      overrideLinkColor: colors.special.color500
    },
    acpSecureUploadTheme: {
      overrideLinkColor: colors.special.color500
    },
    acpUnAuthLayout: {
      overrideLinkColor: colors.special.color500
    },
    acpHandOffTheme: {
      overrideLinkColor: colors.special.color500
    }
  },
  contactInfo: {
    name: 'MLB The Show Prepaid Mastercard',
    contact_address_line1: 'PO Box 2136',
    contact_address_line2: 'Austin, TX 78768-2136',
    contact_address_line3: '',
    customer_support_email: 'customerservice@mlbtheshowprepaid.com',
    fax_formatted: '(866) 358-0526',
    phone_formatted: '(800) 268-8155',
    phone: '8002688155',
    phone_international: '737-220-8956',
    balance_check_phone: '18002688155',
    balance_check_phone_formatted: '1-800-268-8155',
    pre_funded_checks_phone: '18002688155',
    pre_funded_checks_phone_formatted: '1-800-268-8155'
  },
  configuration: {
    apiKeys: {
      appsFlyerDevKey: '5Us7NeWk6WVjGLsAnKioSk',
      appsFlyerBannerKey: '8f3af598-e935-49f0-8382-ede42bb4f20d',
      'google-tag-manager-container-id': 'GTM-NQXKPD',
      'google-tag-manager-container-id-mobile': 'GTM-MWBTT5M',
      paypalFraudNetPayerIdProd: '',
      paypalFraudNetPayerIdTest: ''
    },
    featureToggles: {
      activationSetExternalId: false,
      appsFlyerEnabled: false,
      combinedTransactionsMasterAndSub: false,
      'embedded-activation': true,
      embeddedPayNearMe: false,
      hasNotificationCenter: false,
      mobilePBREnabled: true,
      preOnboardingEnabled: false,
      showAccountDisplayNickname: false,
      showAcquisitionLink: false,
      showATMFriendlyCards: true,
      showDisplayNameForSubAccount: false,
      showEmailInformationButton: true,
      showFeePlanPage: true,
      showHowToEarnRewardPointLink: false,
      showRegisterLink: true,
      showScanCardRegistration: true,
      showMarketingSiteLink: false,
      showFreeAtmFinderLink: false,
      showWUTransfers: false,
      showReloadLocationLink: true
    },
    misc: {
      distributor: 'mlb_theshow',
      layoutComponentOverride: false,
      loader: 'generic',
      payNearMeButtonTextColor: '#FFFFFF',
      payNearMeActionBarBackgroundColor: '#000000',
      payNearMeTitleTextColor: '#FFFFFF',
      payNearMeColorAccent: '#84bd00',
      locationSearchMax: '25',
      iOSAppId: '1498655064'
    },
    content: {
      signUpContent: "Don't have a card? Sign up today."
    },
    disclaimers: {},
    sections: [
      'dashboard-gpr',
      'dashboard-lite',
      'dashboard-handoff',
      'layout',
      'login',
      'logout',
      'manage-gpr',
      'move-money',
      'overdraft',
      'locations',
      'direct-deposit',
      'direct-deposit-mobile-panel',
      'statements',
      'contact-us',
      'restrictions-readonly',
      'feeplan',
      'western-union',
      'disclosure-dictionary',
      'check-deposit',
      'checks',
      'quickbooks-oauth',
      'points',
      'post-handoff-to-activate-register',
      'upgrade',
      'offers',
      'offers-intro',
      'tours',
      'post-install-multi-screen-acquisition-flow',
      'echecks',
      'external-loyalty',
      'secure-inbox',
      'benefit-center',
      'atm-finder',
      'pay-bills',
      'premier-plan',
      'activation',
      'anytime-alerts',
      'anytime-alerts-upgrade',
      'change-username',
      'change-password',
      'mobile-check-load',
      'feedback',
      'rent-track',
      'direct-deposit-form',
      'dynamic-faq',
      'billpay',
      'card',
      'refer-a-friend',
      'close-account',
      'points-learn-more',
      'family-cards',
      'bank-transfers',
      'handoff',
      'debit-card-transfers',
      'card-use-settings',
      'secure-upload',
      'security',
      'digital-wallet',
      'me-to-me-transfer',
      'money-gram',
      'survey',
      'debit-card-transfers-disbursement',
      'spend-money',
      'fraud-detection',
      'spending-tracker',
      'connected-banks',
      'interstitials',
      'dd-tracker'
    ]
  }
};

export default variant;
